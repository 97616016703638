import { Container } from '@think-internet/ui-components'
import Layout from '../../Layout/Layout'
import { levels } from '../../../enum'
import { StyledInfo } from './Info.Styled'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import Headline from '../../SubComponents/Headline/Headline'

const Info = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <Layout requiredLevel={levels.ADMIN}>
      <StyledInfo>
        <Headline text={translation.dashboard.admin.info.headline} align="center" />
        <Container></Container>
      </StyledInfo>
    </Layout>
  )
}

export default Info
