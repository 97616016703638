import { Container } from '@think-internet/ui-components'
import Layout from '../../Layout/Layout'
import { levels } from '../../../enum'
import { StyledOrganizations } from './Organizations.Styled'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import Headline from '../../SubComponents/Headline/Headline'
import Create from './Create/Create'
import List from './List/List'

const Organizations = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <Layout requiredLevel={levels.ADMIN}>
      <StyledOrganizations>
        <Headline text={translation.dashboard.admin.organizations.headline} align="center" />
        <Container>
          <Create />
          <List />
        </Container>
      </StyledOrganizations>
    </Layout>
  )
}

export default Organizations
