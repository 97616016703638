import { StyledList, Headline } from './List.Styled'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import useFunctional from '../../../../../../interface/useFunctional'
import routes from '../../../../../../redux/routes'

const List = ({ organizationUUID }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const listManagers = useFunctional(routes.list_organization_manager)
  const [managers, setManagers] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const list = async () => {
      const managers = await listManagers({ organizationUUID })
      if (Array.isArray(managers)) {
        setManagers(managers)
      }
    }
    if (!managers) {
      list()
    }
  }, [managers, dispatch, listManagers, organizationUUID])

  if (!Array.isArray(managers)) return <></>
  return (
    <StyledList>
      <Headline>{translation.dashboard.admin.organizations.managers.list.headline}</Headline>
      {managers.map((m) => (
        <div key={m.uuid}>{`${m.firstname} ${m.lastname} (${m.mail})`}</div>
      ))}
    </StyledList>
  )
}

export default List
