const routes = {
  get_init_text_response: 'get_init_text_response',
  get_modified_text_response: 'get_modified_text_response',
  get_pdf_download: 'get_pdf_download',
  get_unlimited_chat_response: 'get_unlimited_chat_response',
  upsert_organization_manager: 'upsert_organization_manager',
  list_organization_manager: 'list_organization_manager',
  get_presigned_upload_url_for_organization_logo: 'get_presigned_upload_url_for_organization_logo',
  get_active_organization: 'get_active_organization',
}

export default routes
