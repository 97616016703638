import List from './List/List'
import { Container } from '@think-internet/ui-components'
import Layout from '../../Layout/Layout'
import { levels } from '../../../enum'
import { StyledCapture } from './Capture.Styled'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import Headline from '../../SubComponents/Headline/Headline'
import Create from './Create/Create'

const Capture = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <Layout requiredLevel={[levels.USER, levels.MANAGER]}>
      <StyledCapture>
        <Headline text={translation.dashboard.user.capture.headline} align="center" />
        <Container>
          <Create />
          <List />
        </Container>
      </StyledCapture>
    </Layout>
  )
}

export default Capture
