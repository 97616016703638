import { StyledUpdate, Content, FlexForm, Hero, Hint, Column, CTAWrapper, DownloadButton, DeleteButton } from './Update.Styled'
import { Container, Dropdown, Input, Textarea } from '@think-internet/ui-components'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { update, get, remove } from '../../../../interface/crud'
import call from '../../../../interface/functional'
import props from '../../../../redux/props'
import routes from '../../../../redux/routes'
import toast, { TYPE } from '../../../../toast'
import { downloadFile } from '../../../../utility'
import Layout from '../../../Layout/Layout'
import { levels } from '../../../../enum'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ActionButton from './ActionButton/ActionButton'

const Update = () => {
  const [data, setData] = useState({})
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const dispatch = useDispatch()
  const { uuid } = useParams()
  const [generationInProgress, setGenerationInProgress] = useState(false)
  const discOptions = translation.generic.disc.map((item) => ({ key: item, value: item }))
  const genderOptions = translation.generic.gender.map((item) => ({ key: item, value: item }))

  useEffect(() => {
    const getCapture = async () => {
      const capture = await get(token, props.CAPTURE, { uuid })
      if (capture) {
        setData(capture)
      }
    }
    if (!data || (data.uuid !== uuid && token)) {
      getCapture()
    }
  }, [uuid, data, token, dispatch])

  const updateProp = (prop) => (value) => setData({ ...data, [prop]: value })

  const updateData = async () => {
    const capture = await update(token, props.CAPTURE, data)
    return capture
  }

  const submit = async () => {
    const capture = await updateData()
    if (capture) {
      toast(TYPE.SUCCESS, translation.dashboard.user.capture.update.success.submit)
      setGenerationInProgress(true)
      const response = await call(token, routes.get_init_text_response, { uuid: capture.uuid })
      setGenerationInProgress(false)
      if (response.status === true) {
        setData({
          ...data,
          description: response.response.description,
          descriptionModification: '',
          qualification: response.response.qualification,
          qualificationModification: '',
        })
      }
    } else {
      toast(TYPE.ERROR, translation.dashboard.user.capture.update.error.submit)
    }
  }

  const updateResponse = (inputProp, outputProp) => async () => {
    await updateData()
    if (data[inputProp] && data[inputProp].trim().length > 0) {
      toast(TYPE.SUCCESS, translation.dashboard.user.capture.update.success.modification)
      setGenerationInProgress(true)
      const response = await call(token, routes.get_modified_text_response, { uuid, outputProp, modificationText: data[inputProp] })
      setGenerationInProgress(false)
      if (response.status === true) {
        setData({ ...data, [outputProp]: response.response, [inputProp]: '' })
      } else {
        toast(TYPE.ERROR, translation.dashboard.user.capture.update.error.modification)
      }
    } else {
      toast(TYPE.SUCCESS, translation.dashboard.user.capture.update.success.save)
    }
  }

  const triggerDownload = async () => {
    await updateData()
    const response = await call(token, routes.get_pdf_download, { uuid }, null, null, 'application/pdf')
    if (response) {
      downloadFile(`${data.firstname}-${data.lastname}.pdf`, response)
    } else {
      toast(TYPE.ERROR, translation.dashboard.user.capture.update.error.export)
    }
  }

  const triggerRemove = async () => {
    const result = await remove(token, props.CAPTURE, { uuid: data.uuid })
    if (result) {
      window.location.href = '/dashboard/capture'
    } else {
      toast(TYPE.ERROR, translation.dashboard.user.capture.update.error.remove)
    }
  }

  return (
    <Layout requiredLevel={[levels.USER, levels.MANAGER]}>
      <StyledUpdate>
        <Hero />
        <Container>
          <Content>
            <FlexForm onSubmit={submit}>
              <Column width={3}>
                <Dropdown
                  label={translation.generic.prefixes.gender}
                  onChange={updateProp('gender')}
                  value={data.gender}
                  items={genderOptions}
                  emptySelectionLabel={'-Geschlecht wählen-'}
                />
                <Input value={data.position} onChange={updateProp('position')} label={translation.generic.prefixes.position} />
                <Input value={data.firstname} onChange={updateProp('firstname')} label={translation.generic.prefixes.firstname} />
                <Input value={data.lastname} onChange={updateProp('lastname')} label={translation.generic.prefixes.lastname} />
                <Input value={data.nationality} onChange={updateProp('nationality')} label={translation.generic.prefixes.nationality} />
                <Input
                  value={data.professionalExperience}
                  onChange={updateProp('professionalExperience')}
                  label={translation.generic.prefixes.professionalExperience}
                />
              </Column>
              <Column width={3} gap="25px">
                <Textarea
                  rows="10"
                  value={data.languageSkills}
                  onChange={updateProp('languageSkills')}
                  label={translation.generic.prefixes.languageSkills}
                />
                <Textarea rows="10" value={data.education} onChange={updateProp('education')} label={translation.generic.prefixes.education} />
              </Column>
              <Column width={3} gap="25px">
                <Textarea
                  rows="10"
                  value={data.singleWordDescription}
                  onChange={updateProp('singleWordDescription')}
                  label={translation.dashboard.user.capture.update.inputForm.singleWordDescription}
                />
                <Textarea
                  rows="10"
                  value={data.specialInformation}
                  onChange={updateProp('specialInformation')}
                  label={translation.dashboard.user.capture.update.inputForm.specialInformation}
                />
              </Column>
              <Column width={2}>
                <Textarea
                  rows="10"
                  value={data.specialCharacteristics}
                  onChange={updateProp('specialCharacteristics')}
                  label={translation.dashboard.user.capture.update.inputForm.specialCharacteristics}
                />
              </Column>
              <Column width={2}>
                <Textarea
                  rows="10"
                  value={data.specialKnowledge}
                  onChange={updateProp('specialKnowledge')}
                  label={translation.dashboard.user.capture.update.inputForm.specialKnowledge}
                />
              </Column>
              <Column width={1}>
                <Dropdown
                  label={translation.generic.prefixes.disc}
                  onChange={updateProp('disc')}
                  value={data.disc}
                  items={discOptions}
                  emptySelectionLabel={'-Stilrichtung wählen-'}
                />
              </Column>
              <CTAWrapper margin="0 0 0px 0">
                <Hint>{translation.dashboard.user.capture.update.inputForm.hint}</Hint>
              </CTAWrapper>
              <CTAWrapper justify="center" margin="0 0 15px 0">
                <ActionButton
                  type="submit"
                  width="10px"
                  disabled={generationInProgress}
                  text={translation.dashboard.user.capture.update.inputForm.cta}
                />
              </CTAWrapper>
            </FlexForm>
            <FlexForm onSubmit={updateResponse('descriptionModification', 'description')} gap="10px" margin="50px 0 0 0">
              <Column width={1}>
                <Textarea
                  rows="10"
                  value={data.description}
                  onChange={updateProp('description')}
                  label={translation.dashboard.user.capture.update.descriptionForm.description}
                />
              </Column>
              <Column width={1}>
                <Textarea
                  rows="3"
                  value={data.descriptionModification}
                  onChange={updateProp('descriptionModification')}
                  label={translation.dashboard.user.capture.update.descriptionForm.descriptionModification}
                />
              </Column>
              <CTAWrapper justify={'right'}>
                <ActionButton type="submit" disabled={generationInProgress} text={translation.dashboard.user.capture.update.descriptionForm.cta} />
              </CTAWrapper>
            </FlexForm>
            <FlexForm onSubmit={updateResponse('qualificationModification', 'qualification')} gap="10px" margin="50px 0 0 0">
              <Column width={1}>
                <Textarea
                  rows="10"
                  value={data.qualification}
                  onChange={updateProp('qualification')}
                  label={translation.dashboard.user.capture.update.qualificationForm.qualification}
                />
              </Column>
              <Column width={1}>
                <Textarea
                  rows="3"
                  value={data.qualificationModification}
                  onChange={updateProp('qualificationModification')}
                  label={translation.dashboard.user.capture.update.qualificationForm.qualificationModification}
                />
              </Column>
              <CTAWrapper justify={'space-between'}>
                <DeleteButton onClick={triggerRemove}>{translation.dashboard.user.capture.update.removeProfile}</DeleteButton>
                <ActionButton disabled={generationInProgress} type="submit" text={translation.dashboard.user.capture.update.qualificationForm.cta} />
              </CTAWrapper>
              <CTAWrapper margin="0">
                <DownloadButton onClick={triggerDownload} />
              </CTAWrapper>
            </FlexForm>
          </Content>
        </Container>
      </StyledUpdate>
    </Layout>
  )
}

export default Update
