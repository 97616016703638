import styled from 'styled-components'

export const StyledChat = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  max-height: 100%;
`

export const Messages = styled.div`
  flex: 1 1 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-sizing: border-box;
`

export const Message = styled.div`
  display: flex;
  align-self: ${({ role }) => (role === 'user' ? 'flex-end' : 'flex-start')};
  background-color: ${({ role, theme }) => (role === 'user' ? theme.color.background.red : theme.color.background.secondary)};
  color: ${({ theme }) => theme.color.font.white};
  padding: 10px;
  border-radius: 15px;
  max-width: 75%;
`
