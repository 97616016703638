const props = {
  TOKEN: 'TOKEN',
  ACCOUNT: 'ACCOUNT',
  CURRENTLANG: 'CURRENTLANG',
  TRANSLATION: 'TRANSLATION',

  ACTIVE_ORGANIZATION: 'ACTIVE_ORGANIZATION',

  USER: 'USER',
  ORGANIZATION: 'ORGANIZATION',
  CAPTURE: 'CAPTURE',
  CHAT: 'CHAT',
  CHAT_MESSAGE: 'CHAT_MESSAGE',
}

export default props
