import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useFunctional from '../../../interface/useFunctional'
import { setLocal } from '../../../redux/action/local'
import props from '../../../redux/props'
import routes from '../../../redux/routes'
import useDocumentTitle from './useDocumentTitle'
import useFavicon from './useFavicon'

const InitializeActiveOriganization = () => {
  const dispatch = useDispatch()
  const getActiveOrganization = useFunctional(routes.get_active_organization)
  const activeOrganization = useSelector((s) => s[props.ACTIVE_ORGANIZATION])
  useDocumentTitle(activeOrganization?.name)
  useFavicon(activeOrganization?.imageURL)

  useEffect(() => {
    const get = async () => {
      const host = window.location.host
      const activeOrganization = await getActiveOrganization({ origin: host })
      if (activeOrganization) {
        dispatch(setLocal(props.ACTIVE_ORGANIZATION, activeOrganization))
      }
    }
    if (!activeOrganization) {
      get()
    }
  })

  return <></>
}

export default InitializeActiveOriganization
