import { Button } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { getPrettyDateTime } from '../../../../../utility'
import { StyledItem, Prop, CTAWrapper } from './Item.Styled'

const Item = ({ data }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <StyledItem>
      {(data.firstname || data.lastname) && <Prop bold>{`${data.firstname} ${data.lastname}`}</Prop>}
      <Prop>{`${translation.generic.prefixes.createdOn}${getPrettyDateTime(new Date(data.createdOn))}`}</Prop>
      {data.position && <Prop>{`${translation.generic.prefixes.position}${data.position}`}</Prop>}
      <CTAWrapper>
        <Button href={`/dashboard/organization/${data.uuid}`} text={translation.generic.view} />
      </CTAWrapper>
    </StyledItem>
  )
}

export default Item
