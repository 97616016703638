import { StyledManagers } from './Managers.Styled'
import Upsert from './Upsert/Upsert'
import List from './List/List'

const Managers = ({ organizationUUID }) => {
  return (
    <StyledManagers>
      <Upsert organizationUUID={organizationUUID} />
      <List organizationUUID={organizationUUID} />
    </StyledManagers>
  )
}

export default Managers
