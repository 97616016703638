import { Input, Button } from '@think-internet/ui-components'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import toast, { TYPE } from '../../../../../../toast'
import { CustomForm, Row, StyledUpsert, Headline } from './Upsert.Styled'
import useFunctional from '../../../../../../interface/useFunctional'
import routes from '../../../../../../redux/routes'

const Upsert = ({ organizationUUID, uuid = null }) => {
  const [payload, setPayload] = useState({ uuid, organizationUUID })
  const translation = useSelector((s) => s[props.TRANSLATION])
  const upsert = useFunctional(routes.upsert_organization_manager)

  const updateProp = (prop) => (value) => setPayload({ ...payload, [prop]: value })

  const submit = async () => {
    const user = await upsert(payload)
    if (user) {
      toast(TYPE.SUCCESS, translation.dashboard.admin.organizations.managers.upsert.success.submit)
      if (!uuid) setPayload({ organizationUUID })
    } else {
      toast(TYPE.ERROR, translation.dashboard.admin.organizations.managers.upsert.error.submit)
    }
  }

  return (
    <StyledUpsert>
      {!uuid && <Headline>{translation.dashboard.admin.organizations.managers.upsert.headline.create}</Headline>}
      <CustomForm onSubmit={submit}>
        <Row>
          <Input value={payload.firstname} onChange={updateProp('firstname')} placeholder={translation.generic.firstname} required />
          <Input value={payload.lastname} onChange={updateProp('lastname')} placeholder={translation.generic.lastname} required />
        </Row>
        <Row>
          <Input value={payload.mail} onChange={updateProp('mail')} placeholder={translation.generic.mail} type="email" required />
          <Input value={payload.password} onChange={updateProp('password')} placeholder={translation.generic.password} type={'password'} required />
        </Row>
        <Button type="submit" text={translation.generic.save} />
      </CustomForm>
    </StyledUpsert>
  )
}

export default Upsert
