//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  nav: {
    user: {
      items: [
        { text: 'Profile', href: 'dashboard/capture' },
        { text: 'Freie Eingabe', href: 'dashboard/unlimited' },
      ],
    },
    manager: {
      items: [
        { text: 'Profile', href: 'dashboard/capture' },
        { text: 'Freie Eingabe', href: 'dashboard/unlimited' },
        { text: 'Benutzer', href: 'dashboard/users' },
      ],
    },
    admin: {
      items: [
        { text: 'Home', href: 'dashboard/info' },
        { text: 'Organizations', href: 'dashboard/organizations' },
      ],
    },
    logout: 'Logout',
  },

  dashboard: {
    logoutCTA: 'Ausloggen',
    loginCTA: 'Einloggen',
    user: {
      users: {
        headline: 'Benutzer',
        create: {
          headline: 'Neuen Benutzer anlegen',
          success: {
            submit: 'Benutzer erfolgreich angelegt.',
          },
          error: {
            submit: 'Benutzer konnte nicht angelegt werden.',
          },
        },
        list: {
          headline: 'Bestehende Nutzer',
        },
        update: {
          level: {
            label: 'Berechtigungsstufe',
          },
          success: {
            save: 'Einstellungen gespeichert.',
          },
          error: {
            save: 'Einstellungen konnten nicht gespeichert werden.',
          },
        },
      },
      capture: {
        headline: 'Profile',
        list: {
          headline: 'Bisherige Profile',
          empty: 'Es wurden noch keine Profile erstellt.',
        },
        create: {
          cta: 'Neues Profil starten',
          error: {
            save: 'Es konnte keine neues Profil gestartet werden.',
          },
        },
        update: {
          removeProfile: 'Profil löschen',
          inputForm: {
            singleWordDescription: 'Beschreibung des Bewerbers in drei Wörtern:',
            specialInformation: 'Sonderinformationen:',
            recommendation: 'Empfehlung JA/NEIN?: ',
            specialCharacteristics: 'Besondere Charaktereigenschaften',
            specialKnowledge: 'Besondere Vorkenntnisse/ Fähigkeiten/Auszeichnungen',
            hint: 'Bitte füllen Sie alle Felder aus. Sollten noch Verbesserungen vorgenommen werden, können diese nach dem ersten Durchlauf hinzugefügt werden.',
            cta: 'Senden',
          },
          descriptionForm: {
            description: 'Ausgabe des Beschreibungtexts',
            descriptionModification: 'Gibt es noch Verbesserungsvorschläge oder fehlen Informationen?',
            cta: 'Senden',
          },
          qualificationForm: {
            qualification: 'Ausgabe des Qualifikationstexts',
            qualificationModification: 'Gibt es noch Verbesserungsvorschläge oder fehlen Informationen?',
            cta: 'Senden/Speichern',
          },
          success: {
            submit: 'Text generierung wurde gestartet...',
            modification: 'Textanpassung wurde gestartet...',
            save: 'Inhalte wurden gespeichert.',
          },
          error: {
            submit: 'Profil konnten nicht gespeichert werden.',
            remove: 'Profil konnte nicht gelöscht werden.',
            modification: 'Fehler bei der Verarbeitung der Änderungen.',
            export: 'Profil konnte nicht exportiert werden.',
          },
        },
      },
      unlimited: {
        headline: 'Freie Eingabe',
        create: {
          input: 'Title des neuen Chats',
          cta: 'Neuen Chat starten',
          error: {
            submit: 'Es konnte keine neuer Chat gestartet werden.',
          },
        },
        chat: {
          compose: {
            input: 'Ihre Nachricht...',
            cta: 'Senden',
            error: {
              submit: 'Nachricht konnte nicht verarbeitet werden.',
            },
          },
        },
      },
    },
    admin: {
      info: {
        headline: 'Info',
      },
      organizations: {
        headline: 'Organisationen',
        list: {
          headline: 'Bestehende Organisationen',
          empty: 'Es wurden noch keine Organisationen erstellt.',
        },
        create: {
          cta: 'Neue Organisation anlegen',
          error: {
            save: 'Es konnte keine neue Organisation angelegt werden.',
          },
        },
        update: {
          removeOrganization: 'Organisation löschen',
          cta: 'Speichern',
          logo: {
            title: 'Logo-Upload',
            hint: 'Max. 5 MB',
            maxFileSizeExceededToast: 'Maximale Dateigröße von 5 MB überschritten.',
            invalidFileExtensionToast: 'Ungültige Dateiendung. Erlaubt sind: jpg, jpeg, png, svg.',
          },
          success: {
            submit: 'Organisation erfolgreich gespeichert.',
          },
          error: {
            submit: 'Organisation konnte nicht gespeichert werden.',
            remove: 'Organisation konnte nicht gelöscht werden.',
          },
        },
        managers: {
          upsert: {
            headline: {
              create: 'Neuen Manager anlegen',
            },
            success: {
              submit: 'Manager erfolgreich gespeichert.',
            },
            error: {
              submit: 'Manager konnte nicht gespeichert werden.',
            },
          },
          list: {
            headline: 'Bestehende Manager',
          },
          update: {
            success: {
              save: 'Einstellungen gespeichert.',
            },
            error: {
              save: 'Einstellungen konnten nicht gespeichert werden.',
            },
          },
        },
      },
    },
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  gtc: {
    headline: 'AGB',
  },

  generic: {
    salutation: 'Anrede',
    title: 'Titel',
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    address: 'Adresse',
    street: 'Straße',
    postcode: 'Postleitzahl',
    city: 'Stadt',
    phone: 'Telefon',
    fax: 'Fax',
    mail: 'E-Mail',
    password: 'Passwort',
    km: ' km',
    save: 'Speichern',
    view: 'Einsehen',
    remove: 'Löschen',
    create: 'Erstellen',
    message: 'Nachricht',
    description: 'Beschreibung',
    disc: ['Dominant', 'Initiative', 'Stetig', 'Gewissenhaft'],
    gender: ['Männlich', 'Weiblich'],
    prefixes: {
      position: 'Stelle: ',
      gender: 'Geschlecht: ',
      firstname: 'Vorname: ',
      lastname: 'Nachname: ',
      languageSkills: 'Sprachkenntnisse: ',
      nationality: 'Staatsangehörigkeit: ',
      disc: 'Stilrichtung der Ausgabe: ',
      address: 'Adresse: ',
      organizationName: 'Name der Organisation: ',
      organizationOrigin: 'Host der Organisation (z. B.: think-internet.de): ',
      taxNumber: 'Steuernummer: ',
      phone: 'Telefon: ',
      website: 'Webseite: ',
      contact: 'Kontakt: ',
      age: 'Alter: ',
      education: 'Ausbildung: ',
      professionalExperience: 'Berufserfahrung: ',
      createdOn: 'Erstellt am: ',
    },

    level: {
      ADMIN: 'Administator',
      MANAGER: 'Manager',
      USER: 'Benutzer',
    },
  },

  login: {
    headline: 'Login',
    cta: 'Einloggen',
    error: 'E-Mail Adresse oder Passwort falsch.',
  },

  UI: {
    dropdown: {
      emptySelection: 'Bitte wählen',
    },
  },
}

module.exports = {
  translation,
  langKey: 'DE',
}
