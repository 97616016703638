import { Route, Routes, Navigate } from 'react-router-dom'
import Users from './Users/Users'
import Capture from './Capture/Capture'
import CaptureUpdate from './Capture/Update/Update'
import Unlimited from './Unlimited/Unlimited'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import { levels } from '../../enum'
import Info from './Info/Info'
import Organizations from './Organizations/Organizations'
import OrganizationUpdate from './Organizations/Update/Update'

const Dashboard = () => {
  const account = useSelector((s) => s[props.ACCOUNT])

  return (
    <Routes>
      {/* USER/MANAGER ROUTES */}
      <Route path={'capture'} element={<Capture />} />
      <Route path={'capture/:uuid'} element={<CaptureUpdate />} />
      <Route path={'unlimited'} element={<Unlimited />} />
      <Route path={'unlimited/:uuid'} element={<Unlimited />} />
      {/* MANAGER ROUTES */}
      <Route path={'users'} element={<Users />} />
      {/* ADMIN ROUTES */}
      <Route path={'info'} element={<Info />} />
      <Route path={'organizations'} element={<Organizations />} />
      <Route path={'organization/:uuid'} element={<OrganizationUpdate />} />
      <Route path={'*'} element={<Navigate replace to={!!account && account.level === levels.ADMIN ? 'info' : 'capture'} />} />
    </Routes>
  )
}

export default Dashboard
