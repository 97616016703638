import styled from 'styled-components'

export const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.color.background.primary};
  background-size: cover;
  padding: 50px 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 100px 0 15px 0;
    background-size: auto 100%;
    margin-top: 100px;
  }
`

export const Column = styled.div`
  flex: 1 1 0px;
  margin: ${({ margin }) => margin || '0px'};
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const CustomText = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.secondary};
`

export const Flex = styled.div`
  display: flex;
  gap: 25px;
  align-items: flex-start;
  justify-content: ${({ justify }) => justify || 'center'};
  margin: ${({ margin }) => margin || '0px'};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 30px;
    gap: 40px;
  }
`

export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
`
