import { Form } from '@think-internet/ui-components'
import styled from 'styled-components'
import bg from '../../../../assets/image/user/capture/update/hero/image.jpg'
import downloadButton from '../../../../assets/image/user/capture/update/download.png'

export const StyledUpdate = styled.div``

export const Hero = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  height: 30vh;
`

export const Content = styled.div`
  padding: 50px 0;
`

export const FlexForm = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ gap }) => gap || '25px'};
  ${({ margin }) => margin && `margin: ${margin};`}
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`

export const Hint = styled.div`
  text-align: center;
  width: 100%;
`

export const Column = styled.div`
  display: flex;
  gap: ${({ gap }) => gap || '5px'};
  flex-direction: column;
  width: ${({ width }) => `calc(100% / ${width} - (${width - 1} * 25px / ${width}))`};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const CTAWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin: ${({ margin }) => margin || '15px 0 15px 0'};
  justify-content: ${({ justify }) => justify || 'right'};
  width: 100%;
`

export const DownloadButton = styled.div`
  height: 27px;
  width: 75px;
  background-color: ${({ theme }) => theme.color.background.white};
  border-radius: 5px;
  background-image: url(${downloadButton});
  background-size: auto 17px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`
export const DeleteButton = styled.div`
  color: ${({ theme }) => theme.color.font.red};
  background-color: ${({ theme }) => theme.color.background.primary};
  padding: 5px 15px;
  border: thin solid ${({ theme }) => theme.color.border.primary};
  display: inline-block;
  cursor: pointer;
  font-family: 'bold';
  text-transform: lowercase;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.sm};
  }
  ${({ theme }) => theme?.components?.button?.button};
`

export const Age = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
`
