import { Button } from '@think-internet/ui-components'
import { StyledCreate } from './Create.Styled'
import { create } from '../../../../interface/crud'
import toast, { TYPE } from '../../../../toast'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'

const Create = () => {
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])

  const trigger = async () => {
    const capture = await create(token, props.CAPTURE)
    if (capture) {
      window.location.href = `/dashboard/capture/${capture.uuid}`
    } else {
      toast(TYPE.ERROR, translation.dashboard.user.capture.create.error.submit)
    }
  }

  return (
    <StyledCreate>
      <Button onClick={trigger} text={translation.dashboard.user.capture.create.cta} />
    </StyledCreate>
  )
}

export default Create
