import { Button, Textarea } from '@think-internet/ui-components'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { StyledCompose, FlexForm } from './Compose.Styled'
import useCRUD from '../../../../../interface/useCRUD'
import { setLocal } from '../../../../../redux/action/local'
import routes from '../../../../../redux/routes'
import call from '../../../../../interface/functional'

const Compose = ({ chatUUID, messagesRef }) => {
  const dispatch = useDispatch()
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [message, setMessage] = useState('')
  const { create } = useCRUD(props.CHAT_MESSAGE)
  const [processing, setProcessing] = useState(false)

  const generateAnser = async () => {
    const response = await call(token, routes.get_unlimited_chat_response, { chatUUID })
    setProcessing(false)
    if (response.status === true) {
      setMessage('')
      dispatch(setLocal(props.CHAT_MESSAGE, response.response, false, true))
    }
  }

  const submit = async () => {
    setProcessing(true)
    const result = await create({ chatUUID, message }, translation.dashboard.user.unlimited.chat.compose.error.submit)
    if (result) {
      dispatch(setLocal(props.CHAT_MESSAGE, result, false, true))
      await generateAnser()
    } else {
      setProcessing(false)
    }
  }

  return (
    <StyledCompose>
      <FlexForm onSubmit={submit}>
        <Textarea value={message} onChange={setMessage} placeholder={translation.dashboard.user.unlimited.chat.compose.input} required />
        {!processing && <Button type="submit" text={translation.dashboard.user.unlimited.chat.compose.cta} />}
      </FlexForm>
    </StyledCompose>
  )
}

export default Compose
