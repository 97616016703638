import styled from 'styled-components'

export const StyledList = styled.div`
  margin-top: 50px;
`

export const Headline = styled.div`
  font-family: 'bold';
`

export const NoCaputesYet = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const Results = styled.div``
