import { Button } from '@think-internet/ui-components'
import { StyledCreate } from './Create.Styled'
import toast, { TYPE } from '../../../../toast'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import useCRUD from '../../../../interface/useCRUD'

const Create = () => {
  const create = useCRUD(props.ORGANIZATION).create
  const translation = useSelector((s) => s[props.TRANSLATION])

  const trigger = async () => {
    const organization = await create()
    if (organization) {
      window.location.href = `/dashboard/organization/${organization.uuid}`
    } else {
      toast(TYPE.ERROR, translation.dashboard.admin.organizations.create.error.submit)
    }
  }

  return (
    <StyledCreate>
      <Button onClick={trigger} text={translation.dashboard.admin.organizations.create.cta} />
    </StyledCreate>
  )
}

export default Create
