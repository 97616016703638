import { StyledUpdate, FlexForm, CTAWrapper, DeleteButton } from './Update.Styled'
import { Container, DragAndDrop, Input } from '@think-internet/ui-components'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { update, get, remove } from '../../../../interface/crud'
import props from '../../../../redux/props'
import routes from '../../../../redux/routes'
import toast, { TYPE } from '../../../../toast'
import Layout from '../../../Layout/Layout'
import { levels } from '../../../../enum'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ActionButton from './ActionButton/ActionButton'
import Headline from '../../../SubComponents/Headline/Headline'
import Managers from './Managers/Managers'
import useFunctional from '../../../../interface/useFunctional'
import { s3Upload } from '../../../../fetch'
import LogoPreview from './LogoPreview/LogoPreview'

const Update = () => {
  const [data, setData] = useState({})
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const dispatch = useDispatch()
  const { uuid } = useParams()
  const getPresignedUploadURL = useFunctional(routes.get_presigned_upload_url_for_organization_logo)

  useEffect(() => {
    const getOrganization = async () => {
      const organization = await get(token, props.ORGANIZATION, { uuid })
      if (organization) {
        setData(organization)
      }
    }
    if (!data || (data.uuid !== uuid && token)) {
      getOrganization()
    }
  }, [uuid, data, token, dispatch])

  const updateProp = (prop) => (value) => setData({ ...data, [prop]: value })

  const submit = async () => {
    const organization = await update(token, props.ORGANIZATION, data)
    if (organization) {
      toast(TYPE.SUCCESS, translation.dashboard.admin.organizations.update.success.submit)
    } else {
      toast(TYPE.ERROR, translation.dashboard.admin.organizations.update.error.submit)
    }
  }

  const triggerRemove = async () => {
    const result = await remove(token, props.ORGANIZATION, { uuid: data.uuid })
    if (result) {
      window.location.href = '/dashboard/organizations'
    } else {
      toast(TYPE.ERROR, translation.dashboard.admin.organizations.update.error.remove)
    }
  }

  const processLogo = async (files) => {
    if (Array.isArray(files) && files.length === 1) {
      const logo = files[0]
      const { presignedURL, key } = await getPresignedUploadURL({
        fileMeta: { name: logo.name, type: logo.type, size: logo.size },
        organizationUUID: uuid,
      })
      if (presignedURL && key) {
        const status = await s3Upload(presignedURL, logo)
        if (status) {
          const organization = await update(token, props.ORGANIZATION, { ...data, imageKey: key })
          setData(organization)
        }
      }
    }
  }

  return (
    <Layout requiredLevel={levels.ADMIN}>
      <StyledUpdate>
        <Container>
          <Headline text={data.name} align="center" />
          <FlexForm onSubmit={submit}>
            <Input value={data.origin} onChange={updateProp('origin')} placeholder={translation.generic.prefixes.organizationOrigin} />
            <Input value={data.name} onChange={updateProp('name')} placeholder={translation.generic.prefixes.organizationName} />
            <Input value={data.taxNumber} onChange={updateProp('taxNumber')} placeholder={translation.generic.prefixes.taxNumber} />
            <Input value={data.street} onChange={updateProp('street')} placeholder={translation.generic.prefixes.street} />
            <Input value={data.postcode} onChange={updateProp('postcode')} placeholder={translation.generic.prefixes.postcode} />
            <Input value={data.city} onChange={updateProp('city')} placeholder={translation.generic.prefixes.city} />
            <Input value={data.phone} onChange={updateProp('phone')} placeholder={translation.generic.prefixes.phone} />
            <Input value={data.website} onChange={updateProp('website')} placeholder={translation.generic.prefixes.website} />
            <DragAndDrop
              title={translation.dashboard.admin.organizations.update.logo.title}
              hint={translation.dashboard.admin.organizations.update.logo.hint}
              onFileCallback={processLogo}
              maxFileSizeInBytes={10000000}
              maxFileSizeExceededToast={translation.dashboard.admin.organizations.update.logo.maxFileSizeExceededToast}
              multiple={false}
              allowedFileExtensions={['png', 'jpg', 'jpeg', 'svg']}
              invalidFileExtensionToast={translation.dashboard.admin.organizations.update.logo.invalidFileExtensionToast}
            />
            <LogoPreview imageURL={data.imageURL} />
            <CTAWrapper justify={'space-between'}>
              <DeleteButton onClick={triggerRemove}>{translation.dashboard.admin.organizations.update.removeOrganization}</DeleteButton>
              <ActionButton type="submit" text={translation.dashboard.admin.organizations.update.cta} />
            </CTAWrapper>
          </FlexForm>
          <Managers organizationUUID={uuid} />
        </Container>
      </StyledUpdate>
    </Layout>
  )
}

export default Update
