import { Form } from '@think-internet/ui-components'
import styled from 'styled-components'

export const StyledUpsert = styled.div`
  margin-top: 25px;
`

export const CustomForm = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: right;
`

export const Row = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`

export const Headline = styled.div`
  font-family: 'bold';
`
