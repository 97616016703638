import Layout from '../../Layout/Layout'
import { levels } from '../../../enum'
import { StyledUnlimited, Content } from './Unlimited.Styled'
import Sidebar from './Sidebar/Sidebar'
import Chat from './Chat/Chat'
import Headline from '../../SubComponents/Headline/Headline'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Create from './Create/Create'

const Unlimited = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <Layout requiredLevel={[levels.USER, levels.MANAGER]} hideFooter>
      <StyledUnlimited>
        <Headline text={translation.dashboard.user.unlimited.headline} align={'center'} />
        <Create />
        <Content>
          <Sidebar />
          <Chat />
        </Content>
      </StyledUnlimited>
    </Layout>
  )
}

export default Unlimited
