const realWhite = 'rgb(255,255,255)'
const black = 'rgb(63,63,63)'
const white = 'rgb(230,230,230)'
const lightgray = '#cdcdcd'
const red = 'rgb(228,0,49)'
const blue = 'rgb(46,49,141)'

const theme = {
  font: {
    size: {
      sm: '14px',
      md: '16px',
      lg: '22px',
      xlg: '32px',
      headline: '46px',
    },
  },
  color: {
    font: {
      primary: black,
      secondary: white,
      headline: black,
      nav: black,
      hint: lightgray,
      red: red,
      blue: blue,
      white: realWhite,
    },
    background: {
      primary: realWhite,
      secondary: black,
      red: red,
      white: white,
    },
    border: {
      primary: black,
      secondary: white,
      lightgray,
    },
    lighterBlue: '#a7d1f7',
    lightBlue: '#7daed2',
    darkBlue: '#648db4',

    red: '#e5192c',
  },
  breakpoint: {
    sm: '768px',
    md: '992px',
    lg: '1200px',
  },
  border: {
    radius: '5px',
  },
  shadow: '2px 2px 16px -1px rgba(0,0,0,0.48)',
}

const adjustedComponentStyling = {
  accordion: {
    accordion: ``,
    flex: ``,
    item: {
      item: ``,
      header: ``,
      text: ``,
      openicon: ``,
      body: ``,
    },
  },
  button: {
    button: `
    background-color: transparent;
    border-radius: 5px;
    background-color: ${theme.color.background.white};
    border: none;
    &.danger {
      background-color: ${theme.color.background.red};
      color: ${theme.color.font.secondary};
      border: none;
    }
    `,
    anchor: ``,
  },
  checkbox: {
    checkbox: ``,
    flex: ``,
    checkedicon: ``,
    label: ``,
    styledcheckbox: ``,
  },
  container: {
    container: ``,
  },
  draganddrop: {
    draganddrop: `
    box-sizing: border-box;
    border-radius: 0px;
    background-color: ${theme.color.background.white};
    border: none;
    `,
    title: ``,
    hint: ``,
    filelist: ``,
    filename: ``,
  },
  dropdown: {
    dropdown: `margin-top: 10px;color: ${theme.color.font.darkBlue}; width: 100%;`,
    select: `border-radius: 15px;border: none;padding: 10px;color: ${theme.color.font.darkBlue};background-color: ${theme.color.background.white};`,
    option: ``,
    label: ``,
  },
  form: {
    form: ``,
  },
  headline: {
    headline: ``,
  },
  hero: {
    hero: ``,
  },
  input: {
    input: `
    border-radius: 15px;
    border: none;
    padding: 10px;
    color: ${theme.color.font.darkBlue};
    background-color: ${theme.color.background.white};
    `,
    wrapper: ``,
    label: ``,
  },
  link: {
    link: ``,
  },
  modal: {
    modal: `
    `,
    background: `
    `,
    core: `
    `,
    header: `
    `,
    title: ``,
    close: ``,
    content: ``,
    footer: ``,
  },
  progressbar: {
    progressbar: ``,
    progress: ``,
    label: ``,
  },
  textarea: {
    textarea: `
    background-color: ${theme.color.background.white};
    border-radius: 15px;
    border: none;
    padding: 10px;
    color: ${theme.color.font.darkBlue}`,
    wrapper: ``,
    label: ``,
  },
  datepicker: {
    wrapper: ``,
    label: ``,
  },
}

const exportableTheme = { ...theme, components: adjustedComponentStyling }

export default exportableTheme
