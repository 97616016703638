import styled from 'styled-components'

export const StyledItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  border-top: thin solid ${({ theme }) => theme.color.border.lightgray};
  padding: 10px 0;
  margin: 10px 0;
  &:first-child {
    border-top: none;
  }
`

export const Prop = styled.div`
  width: calc(100% / 2 - 5px / 2);
  ${({ bold }) => bold && `font-family: "bold";`}
`

export const CTAWrapper = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
`
