import { StyledFooter, Flex, Text } from './Footer.Styled'
import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'

const Footer = () => {
  const activeOrganization = useSelector((s) => s[props.ACTIVE_ORGANIZATION])
  const getCurrentFullYear = () => {
    const date = new Date()
    return date.getFullYear()
  }

  return (
    <StyledFooter>
      <Container>
        <Flex justify={'right'}>
          <Text>
            {activeOrganization?.name + ' '}&copy;{` ${getCurrentFullYear()} `}
          </Text>
        </Flex>
      </Container>
    </StyledFooter>
  )
}

export default Footer
