import { StyledList, Headline, NoCaputesYet, Results } from './List.Styled'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { list } from '../../../../redux/action/crud'
import Item from './Item/Item'

const List = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const organizations = useSelector((s) => s[props.ORGANIZATION])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!organizations) dispatch(list(props.ORGANIZATION))
  }, [organizations, dispatch])

  if (!Array.isArray(organizations)) return <></>
  return (
    <StyledList>
      <Headline>{translation.dashboard.admin.organizations.list.headline}</Headline>
      {organizations.length > 0 && (
        <Results>
          {organizations.map((organization) => (
            <Item key={organization.uuid} data={organization} />
          ))}
        </Results>
      )}
      {organizations.length === 0 && <NoCaputesYet>{translation.dashboard.admin.organizations.list.empty}</NoCaputesYet>}
    </StyledList>
  )
}

export default List
