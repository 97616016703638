import { create } from '../../../../interface/crud'
import toast, { TYPE } from '../../../../toast'
const { Input, Container } = require('@think-internet/ui-components')
const { useState } = require('react')
const { useSelector } = require('react-redux')
const { default: props } = require('../../../../redux/props')
const { StyledCreate, FlexForm, CTA } = require('./Create.Styled')

const Create = () => {
  const token = useSelector((s) => s[props.TOKEN])
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [title, setTitle] = useState('')

  const submit = async () => {
    const newChat = await create(token, props.CHAT, { title })
    if (newChat) {
      window.location.href = `/dashboard/unlimited/${newChat.uuid}`
    } else {
      toast(TYPE.ERROR, translation.dashboard.user.unlimited.create.error.submit)
    }
  }

  return (
    <StyledCreate>
      <Container>
        <FlexForm onSubmit={submit}>
          <Input value={title} onChange={setTitle} placeholder={translation.dashboard.user.unlimited.create.input} required />
          <CTA type="submit" text={translation.dashboard.user.unlimited.create.cta} />
        </FlexForm>
      </Container>
    </StyledCreate>
  )
}

export default Create
