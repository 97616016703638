import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { list } from '../../../../redux/action/crud'
import props from '../../../../redux/props'
import { StyledSidebar, Chats, Chat } from './Sidebar.Styled'

const Sidebar = () => {
  const chats = useSelector((s) => s[props.CHAT])
  const dispatch = useDispatch()
  const { uuid } = useParams()

  useEffect(() => {
    if (!chats) {
      dispatch(list(props.CHAT))
    }
  })

  if (!Array.isArray(chats) || chats.length === 0) return <></>

  return (
    <StyledSidebar>
      <Chats>
        {chats &&
          chats.map((chat) => {
            return (
              <Chat key={chat.uuid} active={chat.uuid === uuid} href={`/dashboard/unlimited/${chat.uuid}`}>
                {chat.title}
              </Chat>
            )
          })}
      </Chats>
    </StyledSidebar>
  )
}

export default Sidebar
