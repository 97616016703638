import { Button, Form } from '@think-internet/ui-components'
import styled from 'styled-components'

export const StyledCreate = styled.div`
  margin: 15px 0;
`

export const FlexForm = styled(Form)`
  display: flex;
  gap: 15px;
`

export const CTA = styled(Button)`
  white-space: nowrap;
`
