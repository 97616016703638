import { Button } from '@think-internet/ui-components'
import styled from 'styled-components'

export const CustomButton = styled(Button)`
  width: fit-content;
  color: ${({ theme }) => theme.color.font.red};
  font-family: 'bold';
  text-transform: lowercase;
  ${({ disabled, theme }) =>
    disabled &&
    `
    cursor: not-allowed;
    pointer-events: none;
    color: ${theme.color.font.hint};
  `};
`

export const Loading = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.color.font.red};
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`
