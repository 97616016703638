import styled from 'styled-components'

export const StyledHeader = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 50px;
  box-sizing: border-box;
  transition: all 0.1s ease-in-out;
  ${({ scrolled }) =>
    scrolled &&
    `
      background: rgba(230,230,230, 0.2);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      padding: 15px 20px;
  `}
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 15px 20px;
  }
`

export const ItemWrapper = styled.div`
  display: block;
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: ${({ open }) => (open ? 'flex' : 'none')};
    position: fixed;
    flex-direction: column;
    right: 0px;
    top: 0px;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    padding: 100px 15px;
    background-color: ${({ theme }) => theme.color.background.primary};
    gap: 10px;
    z-index: -1;
  }
`

export const Item = styled.a`
  flex: 1 1 0px;
  margin-left: 25px;
  color: ${({ theme }) => theme.color.font.nav};
  font-size: ${({ theme }) => theme.font.size.lg};
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    font-family: 'bold';
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    font-size: ${({ theme }) => theme.font.size.md};
    margin-left: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex: none;
    color: ${({ theme, isSelected }) => (isSelected ? theme.color.font.blue : theme.color.font.nav)};
    margin-left: 5px;
  }
`

export const LogoWrapper = styled.div``

export const Logo = styled.img`
  cursor: pointer;
  height: 75px;
  ${({ scrolled }) =>
    scrolled &&
    `
      height: 50px;
  `}

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 50px;
  }
`

export const MobileToggle = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`

export const Hamburger = styled.img`
  cursor: pointer;
  height: 30px;
`
