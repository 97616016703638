import styled from 'styled-components'

export const StyledUnlimited = styled.div`
  padding: 150px 0 0 0;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const Content = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
`
