import styled from 'styled-components'

export const StyledLogoPreview = styled.div`
  text-align: center;
  width: 100%;
`

export const Image = styled.img`
  max-height: 150px;
  max-width: 150px;
`
