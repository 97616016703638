import { StyledList, Headline, NoCaputesYet, Results } from './List.Styled'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { list } from '../../../../redux/action/crud'
import Item from './Item/Item'

const List = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const captures = useSelector((s) => s[props.CAPTURE])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!captures) dispatch(list(props.CAPTURE))
  }, [captures, dispatch])

  if (!Array.isArray(captures)) return <></>
  return (
    <StyledList>
      <Headline>{translation.dashboard.user.capture.list.headline}</Headline>
      {captures.length > 0 && (
        <Results>
          {captures.map((capture) => (
            <Item key={capture.uuid} data={capture} />
          ))}
        </Results>
      )}
      {captures.length === 0 && <NoCaputesYet>{translation.dashboard.user.capture.list.empty}</NoCaputesYet>}
    </StyledList>
  )
}

export default List
