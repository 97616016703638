import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { list } from '../../../../redux/action/crud'
import props from '../../../../redux/props'
import Compose from './Compose/Compose'
const { StyledChat, Message, Messages } = require('./Chat.Styled')

const Chat = () => {
  const dispatch = useDispatch()
  const { uuid } = useParams()
  const chatMessages = useSelector((s) => s[props.CHAT_MESSAGE])
  const messagesRef = useRef(null)

  useEffect(() => {
    if (uuid) {
      dispatch(list(props.CHAT_MESSAGE, { chatUUID: uuid }))
    }
  }, [uuid, dispatch])

  useEffect(() => {
    if (messagesRef.current) {
      setTimeout(() => {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight
      }, 1)
    }
  }, [chatMessages])

  if (!uuid) return <></>
  return (
    <StyledChat>
      <Messages ref={messagesRef}>
        {chatMessages &&
          chatMessages.map(({ role, uuid, message }) => {
            return (
              <Message role={role} key={uuid}>
                {message}
              </Message>
            )
          })}
      </Messages>
      <Compose chatUUID={uuid} messagesRef={messagesRef} />
    </StyledChat>
  )
}

export default Chat
