import { Form } from '@think-internet/ui-components'
import styled from 'styled-components'

export const StyledUpdate = styled.div`
  padding: 150px 0;
`

export const FlexForm = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`

export const CTAWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin: ${({ margin }) => margin || '15px 0 15px 0'};
  justify-content: ${({ justify }) => justify || 'right'};
  width: 100%;
`

export const DeleteButton = styled.div`
  color: ${({ theme }) => theme.color.font.red};
  background-color: ${({ theme }) => theme.color.background.primary};
  padding: 5px 15px;
  border: thin solid ${({ theme }) => theme.color.border.primary};
  display: inline-block;
  cursor: pointer;
  font-family: 'bold';
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.sm};
  }
  ${({ theme }) => theme?.components?.button?.button};
`
