import styled from 'styled-components'

export const StyledSidebar = styled.div`
  height: 100%;
  min-width: 300px;
  width: 30vw;
  border-right: thin solid ${({ theme }) => theme.color.border.primary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`
export const Chats = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`

export const Chat = styled.a`
  display: block;
  text-decoration: none;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding: 15px;
  border-top: thin solid ${({ theme }) => theme.color.border.secondary};
  cursor: pointer;
  &:first-child {
    border-top: none;
  }
  &:hover {
    background-color: ${({ theme }) => theme.color.background.white};
  }
  ${({ active, theme }) => active && `background-color: ${theme.color.background.white};`}
`
