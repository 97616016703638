import { useEffect } from 'react'

function useFavicon(imageURL) {
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']")
    link.href = imageURL
  }, [imageURL])
}

export default useFavicon
