import { StyledLogoPreview, Image } from './LogoPreview.Styled'

const LogoPreview = ({ imageURL }) => {
  if (!imageURL) return <></>
  return (
    <StyledLogoPreview>
      <Image src={imageURL} />
    </StyledLogoPreview>
  )
}

export default LogoPreview
